import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    'step1',
    'step2',
    'stepIndex1',
    'stepIndex2',
    'stepIndex3',
    'loginBox',
    'loginBoxMain',
    'loginBoxForm',
    'representativeSubform',
    'termsAcceptCheckbox'
  ]

  connect() {
    this.currentStep = 1;
    this.disablePayButtonOnLoad();
    window.gtag('event', "booking_current_step", { event_category: "booking", event_label: "Current step", value: this.currentStep, name: this[`stepIndex${this.currentStep}Target`]?.textContent });

  }

  showLoginForm(e) {
    e.preventDefault();
    $(this.loginBoxMainTarget).hide();
    $(this.loginBoxFormTarget).removeClass('collapse');
  }

  loginBack(e) {
    e.preventDefault();
    $(this.loginBoxMainTarget).show();
    $(this.loginBoxFormTarget).addClass('collapse');
  }

  toggleRepFields(e) {
    $(this.representativeSubformTarget).toggle();
  }

  validateStep() {
    this.validation__invalid_els = document.querySelectorAll(`[data-new-reservation-form-target=step${this.currentStep}] .form-control:invalid`)
    return (this.validation__invalid_els?.length == 0)
  }

  getFirstInvalidElement() {
    return this.validation__invalid_els[0];
  }

  nextStep(e) {
    e.preventDefault();
    if (this.validateStep()) {
      window.scroll({ top: 0, behavior: 'smooth' });
      if (this.hasLoginBoxTarget) { $(this.loginBoxTarget).hide(); }
      $(this[`step${this.currentStep}Target`]).hide();
      $(this[`step${this.currentStep + 1}Target`]).removeClass('collapse');
      $(this[`stepIndex${this.currentStep}Target`]).removeClass('reservation--progress-item__active');
      $(this[`stepIndex${this.currentStep + 1}Target`]).addClass('reservation--progress-item__active');
      $(this[`stepIndex${this.currentStep}Target`]).addClass('reservation--progress-item__done');
      $(this[`stepIndex${this.currentStep}Target`]).children('.reservation--progress-index').html("<div class='material-symbols-outlined check'>check</div>");
      this.currentStep += 1;

      window.gtag('event', "booking_next_step", { event_category: "booking", event_label: "Move to step", value: this.currentStep, name: this[`stepIndex${this.currentStep}Target`]?.textContent });
    } else {
      window.scroll({ top: this.getFirstInvalidElement().getBoundingClientRect().top, behavior: 'smooth' });
    }
  }

  disablePayButtonOnLoad() {
    const paymentComponent = document.querySelector('#dropin');
    if(!paymentComponent) { return }
    const config = { attributes: false, childList: true, subtree: true };

    const mutationObserver = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (mutation.target.innerHTML.includes('button--pay')) {
          this.updatePayButtons();
        }
      }
    });

    mutationObserver.observe(paymentComponent, config);
  }

  updatePayButtons() {
    for (const payButton of this.payButtons) { 
      payButton.addEventListener('click', (e) => {
        this.verifyTermsAccept(e);
      }, true);
    }
  }

  verifyTermsAccept(e) {
    const termsAcceptCheckbox = this.termsAcceptCheckboxTarget;
    const termsNotAccepted = !termsAcceptCheckbox.checked;
    window.gtag('event', "booking_payment", { event_category: "booking", event_label: "Merchant Pay button - clicked", terms_accepted: termsAcceptCheckbox.checked });

    if (termsNotAccepted) {
      e.stopPropagation();
      termsAcceptCheckbox.scrollIntoView({ behavior: "smooth" })
      termsAcceptCheckbox.style.animation = 'shake 1s';
      setTimeout(() => { termsAcceptCheckbox.style.removeProperty('animation') }, 1000);
      this.indicateCheckbox(termsAcceptCheckbox);
    }
  }

  indicateCheckbox(checkbox) {
    checkbox.style.border = 'red solid 2px';
  }

  get payButtons() {
    return document.querySelectorAll('button.adyen-checkout__button--pay');
  }
}
