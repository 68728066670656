// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import Dialog from '@stimulus-components/dialog'

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
application.register('dialog', Dialog)

import InputCharacterCounterController from "./shared/input_character_counter_controller.js"
application.register("input-character-counter", InputCharacterCounterController)
import LazyLoadController from "./shared/lazy_load_controller.js"
application.register("lazy-load", LazyLoadController)