import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
  static targets = [
    'form',
    'wifiEnabledCheckBox',
    'wifiBatteryEnabledCheckBox',
		'wifiAirportDeliveryCheckBox',
    'wifiAirportDeliveryNotice',
		'wifiHotelDeliveryCheckBox',
    'wifiHotelDeliveryNotice',
    'periodSelect'
  ]

  connect() {
    this.setPricingContext();
    this.toggleAirportFields();
    this.updateSummaryBars();
  }

  setPricingContext() {
    let pricesWifi = [];
    
    _.each(JSON.parse(this.data.get('prices')), function(x){ 
      pricesWifi[x.days] = parseFloat(x.price)
    });

    this.pricingContext = {
      pricesWifi: pricesWifi,
      priceBattery: parseFloat(this.data.get('battery-price'))
    }
  }

  changleDeliveryMethod(event) {
    event.preventDefault();
		var deliveryMethod = $(event.currentTarget).data('deliveryMethod');

    this.updateWifiDeliveryCheckBoxes(deliveryMethod);
    this.toggleAirportFields();

    $('html, body').animate({
      scrollTop: $(`.pickup_option--${deliveryMethod}`).offset().top
    }, 100);
  }

  changeRentalPeriod(event) {
    var currentValue = parseInt(this.periodSelectTarget.value),
        modifier = parseInt(event.currentTarget.dataset.value),
        nextValue = currentValue + modifier,
        maxValue = Array.from(this.periodSelectTarget.children).reduce((opt, res) => { return opt.value > res ? opt.value : res }, 1).value;

    if (nextValue >= 5 && nextValue <= maxValue) {
      this.periodSelectTarget.value = nextValue;
    }
    this.updateSummaryBars();
  }

  toggleForm(event) {
    if (event.currentTarget.checked) {
      $(this.formTarget).removeClass('reservation__pocket_wifi_subform--diabled')
      $(this.formTarget).find('input, select').prop("disabled", false);
      this.toggleAirportFields();
    } else {
      $(this.formTarget).addClass('reservation__pocket_wifi_subform--diabled')
      $(this.formTarget).find('input, select').prop("disabled", true);
    }
    this.updateSummaryBars();

    window.gtag('event', "booking_toggle_pocket_wifi", { event_category: "booking", event_label: "Toggle pocket wifi", value: event.currentTarget.checked});
  }

  toggleAirportFields() {
    if (this.deliveryMethod == 'airport') {
      $('.airport-only').show()
      $('.airport-only').find('input, select').prop("disabled", false);
    } else {
      $('.airport-only').hide()
      $('.airport-only').find('input, select').prop("disabled", true);
    }
  }

  updateSummaryBars() {
    _.debounce(() => {
      this.triggerLoadingEvent({ loading: true, loading_for: 'summary' })

      axios.get(this.element.dataset.calculateCostsUrl, {
        params: {
          wifi_price: this.wifiPrice,
          battery_price: this.wifiBatteryPrice
        }
      }).then((response) => {
        this.updateSummaryBarBottomWifi(response.data);
        this.updateSidebarWifi(response.data);
        this.triggerLoadingEvent({ loading: false, loading_for: 'summary' })
      })
    }, 500)();
  }

  updateSidebarWifi(data) {
    $('#booking_session_sidebar').trigger(`recalculatePrice`, data );
  }

  updateSummaryBarBottomWifi(data) {
    $('#booking_session_summary_bar').trigger(`recalculatePrice`, data);
  }

  triggerLoadingEvent(message) {
    window.dispatchEvent(new CustomEvent('direct-loading', { detail: message }))
  }
  
  get wifiEnabled() {
    return this.wifiEnabledCheckBoxTarget.checked
  }

  get wifiBatteryEnabled() {
    return this.wifiBatteryEnabledCheckBoxTarget.checked
  }

  get deliveryMethod() {
    if (this.wifiAirportDeliveryCheckBoxTarget.checked) {
      return 'airport'
    } else {
      return 'hotel'
    }
  }

  get wifiPrice() {
    var days = this.periodSelectTarget.value,
        price; 

    if (this.wifiEnabled) {
      price = this.pricingContext.pricesWifi[days];
    } else {
      price = 0;
    }

    return price
  }

  get wifiBatteryPrice() {
    var days = this.periodSelectTarget.value,
        price; 

    if (this.wifiEnabled && this.wifiBatteryEnabled) {
      price = this.pricingContext.priceBattery * days;
    } else {
      price = 0;
    }

    return price
  }

  updateWifiDeliveryCheckBoxes(deliveryMethod) {
    const hotelDeliveryMethod = deliveryMethod == 'hotel';

    this.wifiAirportDeliveryCheckBoxTarget.checked = !hotelDeliveryMethod;
    this.wifiAirportDeliveryNoticeTarget.hidden = hotelDeliveryMethod;

    this.wifiHotelDeliveryCheckBoxTarget.checked = hotelDeliveryMethod;
    this.wifiHotelDeliveryNoticeTarget.hidden = !hotelDeliveryMethod;

    if (hotelDeliveryMethod) {
      this.wifiAirportDeliveryCheckBoxTarget.parentNode.classList.remove("pickup_option--active");
      this.wifiHotelDeliveryCheckBoxTarget.parentNode.classList.add("pickup_option--active");
    } else {
      this.wifiAirportDeliveryCheckBoxTarget.parentNode.classList.add("pickup_option--active");
      this.wifiHotelDeliveryCheckBoxTarget.parentNode.classList.remove("pickup_option--active");
    }
  }
}
