import { Controller } from "@hotwired/stimulus";
import { useIntersection } from "stimulus-use";

// Usage:
// <img data-controller="lazy-load" data-lazy-load-src-value="https://example.com/image.jpg" src="data:image/gif;base64,...SOME_BASE64_SMALL_IMG"/>
export default class extends Controller {
  static values = {
    src: String,
    srcPlaceholder: String
  };

  connect() {
    useIntersection(this, { threshold: 0.01, rootMargin: '100px 100px' });

    this.element.src = this.srcPlaceholderValue || this.transparentPlaceholder();
  }

  appear(_entry) {
    if (this.hasSrcValue) {
      this.element.src = this.srcValue;
      this.element.classList.add("img-lazyloaded");
    } else {
      console.warn("Lazy load image missing `data-lazy-load-src-value` attribute");
      this.element.src = "missing-src-value#check-lazy-load-script";
    }
  }

  // Transparent 1x1 pixel
  transparentPlaceholder() {
    "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
  }
}