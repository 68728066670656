import { Controller } from "@hotwired/stimulus";
import axios from 'axios';

export default class extends Controller {
  static targets = ['legend']

  markAvailablePlans(event) {
    event.preventDefault();
    this.resetMarks();

    const data = event.detail[0];
    const invalidPlanIds = []

    if(data.error) {
      alert(data.error);
    } else {
      data.plan_ids.forEach((plan_id) => {
        const row = document.getElementById(plan_id);
        row ? row.style.backgroundColor = this.legendTarget.style.backgroundColor : invalidPlanIds.push(plan_id);
        row.parentNode.insertBefore(row, row.parentNode.firstChild);
      })
      if (invalidPlanIds.length > 0) { alert(`KNT API response contains plan IDs not assigned to the hotel: ${JSON.stringify(invalidPlanIds)}`) }
    }
  }

  resetMarks() {
    Array.from(this.element.querySelectorAll('table tr')).forEach((tr) => tr.style.backgroundColor = 'transparent')
  }
}
