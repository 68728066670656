import { Controller } from "@hotwired/stimulus";
import axios  from 'axios';

export default class extends Controller {
  static values = {
    url: String,
    arrivalDate: String
  };

  verify(event) {
    event.preventDefault();
    
    axios.get(this.urlValue, { params: { arrival_date: this.arrivalDateValue } }).then((res) => { 
      alert(`${JSON.stringify(res.data.response)}`)
    });
  }
}
