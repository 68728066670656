import { Controller } from "@hotwired/stimulus";
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = [
    "statusMessageWrapper",
    "statusMessageTemplatePending",
    "statusMessageTemplatePaid",
    "statusMessageTemplateConfirmed",
    "statusMessageTemplateProcessing",
    "statusMessageTemplateError",
    "statusMessageTemplateCancelled",
  ]

  initialize() {
    this.uuid = this.data.get('uuid')
  }

  connect() {
    this.setChannel();
  }

  disconnect() {
    this.channel.unsubscribe();
  }

  setChannel() {
    var self = this;

    return new Promise((resolve) => {
      self.channel = consumer.subscriptions.create(
        {
          channel: 'ReservationChannel',
          uuid: self.uuid
        },
        {
          connected() {
            this.perform('check_status', self.searchParams);
            resolve();
          },
          received(data) {
            if (data['action'] === 'statusUpdate') {
              let { status, message } = message = data.payload;
              self.changeStatusMessage(status, message);
            };
          }
        });
    });
  };

  changeStatusMessage(status, _message) {
    // set autoreload if still processing
    if (status == 'processing') { this.reloadPageOnce(); return };

    // instead compiling custom messages here we put whole mesage templates in view and now just switch their visibility
    const actionName = `statusMessageTemplate${this.capitalizeFirstLetter(status)}Target`;
    const tpl = this[actionName]?.innerHTML;
    if (tpl) {
      // show reservationMessage by simple reset classes to wrapper and incommineg status
      this.statusMessageWrapperTarget.className = `reservation__message reservation__message--${status}`

      this.statusMessageWrapperTarget.innerHTML = tpl
    };

    window.gtag('event', "booking_finished", { event_category: "booking", event_label: "Booking success page", reservation_uuid: this.uuid, reservation_status: status });
  };

  reloadPageOnce() {
    const currenthash = location.hash;
    const visitedHash = 'check'
    if (currenthash != visitedHash) {
      setTimeout(function () {
        location.hash = visitedHash;
        location.reload();
      }, 10000); // 10000 milliseconds = 10 seconds
    }
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

}
